<template>
  <Card class="card">
    <div class="label-list-container">
      <div class="label-value-container" v-for="(item, index) in config" :key="index">
        <div class="label">{{item.label}}</div>
        <div class="value">{{info[item.field]}}</div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/common/card/whiteCard.vue"

export default {
  components: {
    Card
  },
  props: {
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    config: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  .label-list-container {
    padding-top: 10px;
    padding-bottom: 12px;
  }
  .label-value-container {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    &:not(:last-child){
      margin-bottom: 6px;
    }
    .label {
      color: #818181;
    }
    .value {
      color: #2C2C2C;
      margin-left: 6px;
    }
  }
}
</style>