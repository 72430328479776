<template>
  <div class="common-table">
    <div class="theader">
      <div class="th" v-for="col in computedColumns" :key="col.props">
        {{col.name}}
      </div>
    </div>
    <div class="tbody" :style="{'margin-top': layer ? '12px' : '0'}">
      <div class="tr" v-for="(row, idx) in data" :key="idx">
        <div class="td" v-for="col in columns.slice(0,4)" :key="col.props + idx">
          {{row[col.props]}}
        </div>
        <div class="td delete" v-if="showOp">
          删除
        </div>
      </div>
      <div class="tr" v-if="showOp">
        <div class="td" v-for="col in columns.slice(0,4)" :key="col.props">
          <Field 
            class="field"
            v-model="inputValue[col.props]"
          >
          </Field>
        </div>
        <div class="td delete" @click="handleAdd">
          添加
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field } from "vant";
export default {
  components: {
    Field
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => [],
    },
    layer: {
      type: Boolean,
      default: false
    },
    showOp: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedColumns() {
      if (!this.showOp) {
        return this.columns.slice(0, 4)
      }
      else {
        return this.columns
      }
    }
  },
  data() {
    return {
      inputValue: {
        technologyName: '',
        matchEq: '',
        brandName: '',
        stockVolume: ''
      }
    }
  },
  watch: {
    inputValue: {
      handler() {
      },
      deep: true
    }
  },
  methods: {
    handleAdd() {
      this.$emit('add', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.common-table {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2C2C2C;
  .theader {
    background-color: #fff;
    display: flex;
    align-items: center;
    min-height: 44px;
    line-height: 20px;
    // line-height: 44px;
    .th {
      flex: 1;
    }
  }
  .tr {
    background-color: #fff;
    display: flex;
    align-items: center;
    line-height: 32px;
    .td {
      display: flex;
      flex: 1;
      justify-content: center;
      .field {
        flex-shrink: 0;
        width: 55px;
        height: 25px;
        background: #ffffff;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 5px;
        padding: 2px 8px;
        ::v-deep .van-field__body {
          height: 100%;
        }
        ::v-deep .van-field__control {
          text-align: center;
        }
      }
    }
    .delete {
      color: #CD001F;
    }
  }
}
</style>