<template>
  <!-- 承运人 -->
  <div class="carrier-info-container">
    <Header />
    <div class="card-container">
      <div class="card" v-for="(info, index) in infos" :key="index">
        <InfoItem class="info-item" v-for="(item, index) in config" :key="index+item.type" :info="info" :type="item.type" :data="item.data" :dataTwo="item.dataTwo" :valueColor="item.color" />
      </div>
    </div>
    <div class="loading-container"  v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import InfoItem from './components/infoItem.vue'
import Header from '@/components/header/index.vue'
import { getOrderLogistics } from '@/api/order'
import { Loading } from "vant"

export default {
  components: {
    InfoItem,
    Header,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      id: '',
      infos: [],
      config: [
        {
          type: 'double',
          data: {
            label: '车牌号',
            field: 'carNumber',
            color: '#2C2C2C'
          },
          dataTwo: {
            label: '配载单号',
            field: 'pzCode',
            color: '#2C2C2C'
          }
        },
        {
          type: 'double',
          data: {
            label: '承运人',
            field: 'driverName',
            color: '#2C2C2C'
          },
          dataTwo: {
            label: '承运商',
            field: 'organName',
            color: '#2C2C2C'
          }
        },
        {
          type: 'double',
          data: {
            label: '电话',
            field: 'phone',
            color: '#006EFB'
          },
          dataTwo: {
            label: '数量',
            field: 'amount',
            color: '#2C2C2C'
          }
        },
        {
          type: 'line',
          data: {
            label: '物料名称',
            field: 'materialName',
            color: '#006EFB'
          }
        },
        {
          type: 'double',
          data: {
            label: '办单状态',
            field: 'printStatus',
            color: '#006EFB'
          },
          dataTwo: {
            label: '签收状态',
            field: 'signTime',
            color: '#2C2C2C'
          }
        },
        {
          type: 'double',
          data: {
            label: '入厂状态',
            field: 'isEnter',
            color: '#006EFB'
          },
          dataTwo: {
            label: '出厂状态',
            field: 'isOut',
            color: '#2C2C2C'
          }
        },
        {
          type: 'line',
          data: {
            label: '配载时间',
            field: 'createTime',
            color: '#2C2C2C'
          }
        },
        {
          type: 'line',
          data: {
            label: '入厂时间',
            field: 'enterTime',
            color: '#2C2C2C'
          }
        },
        {
          type: 'line',
          data: {
            label: '出厂时间',
            field: 'outTime',
            color: '#2C2C2C'
          }
        },
        {
          type: 'line',
          data: {
            label: '签收时间',
            field: 'signTime',
            color: '#2C2C2C'
          }
        },
      ]
    }
  },
  methods: {
    async fetchInfo() {
      let response = await getOrderLogistics({id: this.id || "2020111800000173"})
      this.isLoading = false
      this.infos = response.data || []
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.fetchInfo()
  }
}
</script>

<style lang="scss" scoped>
.carrier-info-container {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .card-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    .card {
      margin-top: 12px;
      width: 100%;
      box-sizing: border-box;
      padding: 17px 12px 16px;
      background: white;
      box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
      border-radius: 10px;
      .info-item:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>