import { post } from '@/utils/request'

export const fetchStockList = async data => {
  return await post('/appdealer/api/terminal/visit/stock/list', data)
}

export const fetchInvoiceInfo = async data => {
  return await post('/appdealer/api/eb/order/getOrderInvoiceList', data)
}

export const saveStoreInfo = async data => {
  return await post('/appdealer/api/terminal/visit/stock/save', data) 
}