<template>
  <div class="container">
    <div class="label-value-wrap" :class="{'double-left': type==='double', line: type!=='double'}" :style="{color: valueColor}" >
      <div class="label">{{data.label}}</div>
      <div class="value">{{info[data.field]}}</div>
    </div>
    <div class="label-value-wrap" v-if="type==='double'" :class="{'double-right': type==='double', line: type!=='double'}" >
      <div class="label">{{dataTwo.label}}</div>
      <div class="value">{{info[dataTwo.filed]}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //  line double
    type: { 
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: ()=>{
        return {
          label: '', 
          field: ''  
        }
      }
    },
    dataTwo: {
      type: Object,
      default: ()=>{
        return {
          label: '', 
          field: ''  
        }
      }
    },
    valueColor: {
      type: String,
      default: '#2C2C2C'
    },
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 20px;
  display: flex;
  text-align: left;
  font-size: 14px;
  .line {
    width: 100%;
    height: 100%;
  }
  .double-left {
    width: 45%;
    height: 100%;
  }
  .double-right {
    width: 55%;
    height: 100%;
  }
  .label-value-wrap { 
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    .label {
      color: #818181;
      white-space:nowrap;
    }
    .value {
      margin-left: 6px;
      line-height: 20px;
    }
  }
}
</style>