<template>
  <div class="contacts-info-page">
    <Header />
    <DateRangePicker @changeStart="handleChangeStart" @changeEnd="handleChangeEnd" />
    <Card class="card" v-for="(item, index) in list" :key="index" :info="item" :config="item.config" />
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import DateRangePicker from '@/components/select/dateRangePicker.vue'
import Card from './components/contactsCard.vue'
import { fetchFiList } from '@/api/home'
import InfiniteLoading from 'vue-infinite-loading'
import dayjs from 'dayjs'
import { Loading } from 'vant'

export default {
  components: {
    Header,
    DateRangePicker,
    InfiniteLoading,
    Card,
    Loading,
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 5,
      list: [],
      total: 0,
      beginDate: new Date(`${dayjs(new Date()).format('YYYY')}-01-01`),
      endDate: new Date(dayjs(new Date()).format('YYYY-MM-DD')),
      isLoading: false,
    }
  },
  activated() {
    this.resetList();
  },
  methods: {
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length <= 0) {
        $state.complete();
      }
    },
    async fetchList() {
      let response = []
      let data = {
        "dealerId": "1000443827",
        "endDate": dayjs(this.endDate).format('YYYYMMDD'),
        "beginDate": dayjs(this.beginDate).format('YYYYMMDD'),
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        page: {
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }
      }
      this.isLoading = true
      response = await fetchFiList(data)
      this.isLoading = false
      this.total = response.page.total;
      this.list = this.list.concat(response.data.map(d => {
        return {
          ...d,
          config: [
            {label: '摘要', field: 'sgtxt'},
            {label: '记账日期', field: 'budat'},
            {label: d.sgtxt === '银行收款' ? '收款' : '耗用', field: d.sgtxt === '银行收款' ? 'hmbtr' : 'smbtr'},
            {label: '余额', field: 'kyyue'},
          ] 
        }
      }))
    },
    handleChangeStart(e) {
      this.beginDate = e.date
      this.resetList()
    },
    handleChangeEnd(e) {
      this.endDate = e.date
      this.resetList()
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-info-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  padding-bottom: 20px;
  .card {
    margin-top: 12px;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>