import { post } from '@/utils/request'

export const fetchOrderList = async data => {
  return await post('/appdealer/api/eb/order/getorderlist', data)
}
// 承运人
export const getOrderLogistics = async data => {
  return await post('/appdealer/api/eb/order/getOrderLogistics', data)
}
// 物流竞价
export const getOrderBid = async data => {
  return await post('/appdealer/api/eb/order/getOrderBid', data)
}

export const confirmOrder = async data => {
  return await post('/appdealer/api/eb/order/orderSure', data)
}

export const saveOrder = async data => {
  return await post('/appdealer/api/eb/order/saveorder', data)
}

export const getOrderOverview = async data => {
  return await post('/appdealer/api/report/indexOrderOverview', data)
}

export const fetchOrderDetail = async data => {
  return await post('/appdealer/api/eb/order/getorderdetail', data)
}
// 所属经销商列表
export const fetchDealerList = async data => {
  return await post('/appdealer/api/dealer/get_dealerList', data)
}

export const fetchDictList = async data => {
  return await post('/appdealer/api/dict/get_DictList', data)
}

export const fetchAccount = async data => {
  return await post('/appdealer/api/eb/order/getDealerAccount', data)
}

export const fetchSetDetail = async data => {
  return await post('/appdealer/api/eb/order/getsetmealproducts', data)
}

export const fetchPublicityList = async data => {
  return await post('/appdealer/api/eb/order/getPublicitys', data)
}

export const checkOrderPromotion = async data => {
  return await post('/appdealer/api/eb/order/checkOrderPromotion', data)
}

export const getDealerById = async data => {
  return await post('/appdealer/api/dealer/get_dealerById', data);
}

export const getSetAuth = async data => {
  return await post('/appdealer/api/eb/order/getSetmealAuth', data);
}

export const getProductInfo = async data => {
  return await post('/appdealer/api/eb/order/getMaterial', data)
}